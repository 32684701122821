
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.layout {
    display: grid;
    grid-template-areas:
        'header'
        'progress'
        'content'
        'footer';
    grid-template-rows: auto max-content 1fr auto;
    min-height: 100svh;
    height: 100svh;
    min-width: 32rem;
    background: var(--neutral-black-0);

    .progress {
        span {
            height: 0.5rem;

            span {
                margin-right: 0.3rem;
            }
        }

        @include respond(tab-port) {
            padding: 0.6rem 1.8rem 0.6rem 1.8rem;
        }
    }


    .content {
        grid-area: content;
        width: 100%;
    }

    .header {
        grid-area: header;
        background: var(--neutral-black-0);
    }

    .footer {
        grid-area: footer;
        justify-content: center;
    }
}