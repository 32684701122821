
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.container {

    .title {
        margin-bottom: 1.6rem;
    }

    .subtitle,
    .toptext {
        margin-bottom: 2.4rem;
    }

    .toptext {
        display: flex;
    }
}