
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.layout {
    display: grid;
    grid-template-areas:
        'header'
        'progress'
        'content'
        'footer';
    grid-template-rows: auto max-content 1fr auto;
    min-height: 100vh;
    height: 100%;
    min-width: 32rem;
    background: var(--neutral-black-0);

    .content {
        grid-area: content;
        padding: 4rem;
        width: 100%;
        margin: 0 auto;
        max-width: 120rem;

        @include respond(phone) {
            padding: 1.6rem 2.4rem 2.4rem;
        }
    }

    .header {
        grid-area: header;
        height: 8rem;

        @include respond(tab-port) {
            height: 6rem;
        }
    }

    .footer {
        grid-area: footer;
        padding: 0 4.8rem 2.4rem;

        @include respond(phone) {
            padding: 4.8rem 2.4rem 1.2rem;
        }
    }
}