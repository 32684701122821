
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.actions,
.content {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    .cancel {
        text-transform: none;
        font-weight: 400;
        text-decoration: underline;
    }
}