
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.merchantLogo {
    height: 4.8rem;


    @include respond(phone) {
        height: 3.2rem;
    }
}