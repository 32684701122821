$ultraLightBlue: #d9ebf6;
$lightBlue: #66aaea;
$darkBlue: #041e42;
$lightGrey: #E5E5E5;
$tileBlue: #F2F8FD;
$tileDateBlue: #D3EFF8;

:root {
    --color-tile-light-blue:#{$tileBlue};
    --color-tile-date-light-blue:#{$tileDateBlue};
    --color-ultra-light-blue:#{$ultraLightBlue};
    --color-light-blue:#{$lightBlue};
    --color-dark-blue: #{$darkBlue};
    --color-light-grey: #{$lightGrey};
}