
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.header {
    padding: 1.6rem 2.4rem 2.4rem 2.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--header-color);

    @include respond(tab-port) {
        padding: .8rem 1.6rem 1.6rem 1.6rem;
    }

    .switcher {
        font-size: 2.1rem;
        color: #{var(--header-locale-text-color)};
    }

}