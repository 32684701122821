
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.button {
    min-height: 5.4rem;
    padding: .8rem 5.4rem !important;
    border-radius: 70px !important;
    text-transform: none !important;
    box-shadow: none !important;
    font-size: 1.8rem !important;
    line-height: 2.6rem !important;

    &:active {
        box-shadow: none !important;
        background-color: #{var(--color-dark-blue)};
    }
}