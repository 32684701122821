
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.footer {
    .top {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .divider {
            border-right-width: 0.2rem;
            margin: 0 2rem;
            height: 1.6rem;
            align-self: unset;
        }
    }

    .bottom {
        margin-top: 0.9rem;
        padding: 0.75rem;
        border-top: 1px solid #e0dbe9;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .disclaimer {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            svg {
                fill: #{var(--neutral-black-70)};
            }
        }

        .privacy {
            margin-top: 0.5rem;
            color: #{var(--secondary-color)};
            font-size: 1.6rem;
        }
    }
    padding-bottom: 2rem;
}
