
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.content {
    max-width: 56rem;
    margin: 0 auto;
    padding-top: 6.4rem;
    height: 100%;
    padding-bottom: 3.2rem;

    @include respond(tab-port) {
        margin-top: 0;
        padding: 3.2rem;
    }
}