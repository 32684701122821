
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 12rem;
    padding: 0rem;
    max-width: 144rem;
    margin: 0 auto;
    justify-content: center;

    @include respond(tab-port) {
        height: 7rem;
    }

    .logo {
        padding: 0rem 3rem;
        margin-bottom: 2.4rem;
        margin-top: 2.4rem;

        display: flex;
        justify-content: space-between;
        align-items: center;
        

        @include respond(tab-port) {
            margin-bottom: 0;
            margin-top: 0;
            img {
                max-height: 4.5rem;
            }
        }
    }

    .rightContent {
        display: flex;
        gap: 5rem;
        .switcher {
            font-size: 1.6rem;
            color: #{var(--header-locale-text-color)};
            text-decoration: none;
        }
    }
}
