
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.sessionContainer {
    button {
        border-radius: 70px !important;
        box-shadow: none !important;
        font-size: 1.8rem !important;
        line-height: 2.6rem !important;
        min-height: 5.4rem;
        padding: .8rem 5.4rem !important;
        text-transform: none !important;
        width: fit-content;
        margin-bottom: 1rem;
        word-break: break-word;
    }
}