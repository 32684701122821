
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.container {
    * {
        margin-bottom: 1.6rem;
    }
    .piggybank {
        margin-top: 4.8rem;
    }
    
    .subtitle {
        margin-bottom: 2.4;
    }
}