
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.sessionPopUp {
    display: grid;
    grid-gap: 2.2rem;
    margin: 2rem 0 1.5rem;

    .title {
        font-size: 3rem;
        font-weight: 600;
        letter-spacing: -.05rem;
        line-height: 1.2;
    }

    .message {
        color: var(--font-body3-color);
        font-size: 1.6rem;
        line-height: 1.2;
    }
}
