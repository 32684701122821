
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.footer {
    grid-area: footer;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-top: auto;
    width: 100%;
    height: 5.4rem;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0rem 3rem;
    color: var(--neutral-black-0);
    background: #0071dc;
    flex-shrink: 0;
}
