
                    @import '~@fairstone/style/themes/fairstone/abstracts/mixins';
                    @import 'src/assets/scss/_variables.scss';
                    @import 'src/assets/scss/_global.scss';
                
.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 12rem;
    padding: 0rem;
    justify-content: flex-end;

    @include respond(phone) {
        height: auto;
    }

    .switcher {
        color: #{var(--header-locale-text-color)};
        text-decoration: none;
        line-height: 1.4;
        font-size: 1.6rem;
        font-weight: 400;
        &:hover {
            color: #{var(--button-text-hover-text-color)};
        }
    }

    .logo {
        padding: 0rem 3rem;
        margin-bottom: 2.4rem;
        margin-top: 2.4rem;

        @include respond(phone) {
            margin-bottom: 0;
            margin-top: 2rem;
        }

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-height: 5.4rem;

        .ButtonsWrapper {
            display: flex;

            .divider {
                border-color: #{var(--header-locale-text-color)};
                height: 2rem;
                margin: 0 2.4rem;
            }
        }
    }

    .button {
        text-transform: none;
        justify-content: end;
        font-weight: 400;
        padding: 0;
    }
}

.header:focus {
    outline: none;
}